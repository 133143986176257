import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Comingsoon from "components/Common/CommingSoon";
import ExternalLinks from "pages/ExternalLinks";
import Notifications from "pages/Notifications/View";
import FeedBack from "pages/Feedback";
import CreateFeedback from "pages/Feedback/CreateFeedback";
import ChatWindow from "pages/Feedback/ChatWindow";
import MediaView from "components/Common/MediaView";
import HandleRoutes from "pages/HandleRoutes";
import CreateNotifications from "pages/Notifications/Create";
import ViewTimeTable from "pages/TimeTable/View";
import PaidFeesDetails from "pages/Fees/PaidFeesDetails";
import PrintRecipt from "pages/Fees/PaidFeesDetails/PrintRecipt";
import Attendance from "pages/Attendance/Teacher";
import Gallery from "pages/Gallery";
import ImageGallery from "pages/Gallery/ImageGallery";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/dashboard/parent", component: <Dashboard /> },
  { path: "/dashboard/teacher", component: <Dashboard /> },
  { path: "/dashboard/admin", component: <Dashboard /> },

  //Utilities
  { path: "/external-link", component: <ExternalLinks /> },
  { path: "/media-preview", component: <MediaView /> },
  { path: "/handleroutes", component: <HandleRoutes /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/create-notifications", component: <CreateNotifications /> },
  { path: "/feedback", component: <FeedBack /> },
  { path: "/feedback/create", component: <CreateFeedback /> },
  { path: "/feedback/chats", component: <ChatWindow /> },
  { path: "/timetable", component: <ViewTimeTable /> },

  { path: "/paidfeesdetails", component: <PaidFeesDetails /> },
  { path: "/printpaidfeesdetails", component: <PrintRecipt /> },

  { path: "/teacher/attendance", component: <Attendance /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/gallery/images", component: <ImageGallery /> },



  


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "*",
    component: <Comingsoon />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
